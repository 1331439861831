@tailwind base;
@tailwind components;
@tailwind utilities;
@import "ckeditor.css";
@import "cropperjs/dist/cropper.css";

@layer base {
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat-Bold";
    src: url("./fonts/Montserrat-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: "Montserrat-medium";
    src: url("./fonts/Montserrat-Medium.ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat-semiBold";
    src: url("./fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
  }
  @font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-Regular.ttf");
    font-weight: 400;
  }
  @font-face {
    font-family: "NotoSerif-Bold";
    src: url("./fonts/NotoSerif-Bold.ttf");
    font-weight: 700;
  }
  @font-face {
    font-family: "NotoSerif-Light";
    src: url("./fonts/NotoSerif-Light.ttf");
    font-weight: 700;
  }
}

@layer components {
  .base-button {
    @apply uppercase bg-black-900 text-white min-w-[186px] h-[44px] hover:bg-black-300;
  }
  .border-button {
    @apply uppercase border border-black-900 hover:text-gray-700 hover:border-gray-700 hover:bg-white;
  }
  .normal-button-padding {
    @apply px-4 py-3;
  }
  /* checkbox start */
  .base-checkbox label:not(.flex) {
    @apply block;
  }
  .base-checkbox input {
    @apply opacity-0 absolute;
  }
  .base-checkbox input[type="checkbox"] {
    @apply box-border p-0;
  }
  .base-checkbox span {
    @apply relative inline-block w-[15px] h-[15px] align-middle border border-gray-800 cursor-pointer;
  }
  .base-checkbox span:hover {
    @apply border-black-900;
  }
  .base-checkbox span.error {
    @apply relative inline-block w-[15px] h-[15px] align-middle border-2 border-red-100 cursor-pointer;
  }

  .base-checkbox input:checked + span {
    @apply border-black-900;
  }
  .base-checkbox input:checked + span:after {
    @apply content-[''] block absolute top-px left-px w-[11px] h-[11px] bg-black-900;
  }
  .base-checkbox input:disabled,
  .base-checkbox input:disabled + span {
    @apply bg-gray-200 bg-opacity-40 border-gray-200 cursor-default;
  }
  /* checkbox end */

  .filter-section {
    margin-bottom: 30px;
  }

  .filter-label {
    @apply font-bold text-sm block uppercase;
  }

  .base-popup {
    @apply relative p-5 bg-white max-h-full overflow-visible;
  }

  .base-legendInput-input {
    @apply border rounded-none w-full pl-3 pr-1 pt-[18px] pb-[2px] outline-none text-black-900 opacity-100 disabled:bg-gray-200 disabled:bg-opacity-40 placeholder:text-gray-700;
  }
  .base-stripe-container {
    @apply px-3.5 pt-3 pb-2.5 mb-2.5;
  }
  .translate-3d {
    -webkit-transform: translate3d(0, 0, 0);
  }

  /* hide scrollbar for chrome safari and opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* hide scrollbar for ie, edge and firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

body {
  min-width: 310px;
}

/* reset ios input native UI */
input {
  border-radius: 0;
}
input:focus {
  outline: none !important;
}

input[type="password"] {
  font-family: small-caption;
}

input[type="search"] {
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* FIXME 02/18 
extract mui slider related styles to separate stylesheet
*/
.my-slider {
  color: "#90caf9";
  height: 4px;
  width: 100%;
  padding: 13px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.75;
}

.my-slider:hover {
  opacity: 1;
}

.my-slider .MuiSlider-rail {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: gray;
  opacity: 0.38;
}

.my-slider .MuiSlider-track {
  display: block;
  position: absolute;
  height: 4px;
  border-radius: 2px;
  background-color: black;
}

.my-slider .MuiSlider-thumb {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-left: -6px;
  margin-top: -5px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 0;
  border: 2px solid black;
  background-color: black;
}

.my-slider .MuiSlider-valueLabel {
  display: none;
  position: absolute;
  bottom: 1.5rem;
  left: -24px;
}

.my-slider .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
  display: block;
}

.my-slider .MuiSlider-valueLabel::after {
  @apply absolute;
  content: "";
  top: 100%;
  left: 50%;
  margin-left: -3px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #000;
  border-bottom: 6px solid transparent;
}

.my-slider .MuiSlider-valueLabelCircle {
  @apply bg-black-900 text-white p-1.5 rounded text-xs shadow-sm;
}

.Toastify__toast--error {
  background-color: #a70000;
}

.Toastify__toast--success {
  background-color: #c8a56c;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  height: auto !important;
}

.custom-swiper-bullet {
  width: 15px;
  height: 5px;
  border-top: 2px solid #aaa;
  margin-top: 1px;
  cursor: pointer;
}

.custom-swiper-bullet-active {
  width: 30px;
  border-top: 4px solid #000;
  margin-top: 0;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* addthis */
div.at-share-tbx-element .at-share-btn {
  border: 1px solid #707070;
  margin-right: 15px;
}
/* FIXME
   extract datepicker styles to external stylesheet
*/
.react-datepicker-popper {
  z-index: 9999;
}
.react-datepicker {
  border-radius: 0;
  box-shadow: 0 0 4px #ced4da;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.react-datepicker__input-container {
  max-width: 540px;
}
.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
  color: #495057;
}
.react-datepicker__day-name {
  font-size: 0.75rem;
}
.react-datepicker__month {
  margin-top: 0;
}
.react-datepicker__day {
  color: #495057;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f6f6f6;
  border-radius: 0;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #343a40 !important;
  border-radius: 0 !important;
  color: #eff2f7 !important;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #343a40 !important;
  border-radius: 0 !important;
  color: #eff2f7 !important;
}
.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: none;
  color: black;
}
.react-datepicker__day-name,
.react-datepicker__day--outside-month {
  color: #adb5bd;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  text-decoration: underline;
}
.react-datepicker__input-time-container {
  margin: 0;
  border-top: 1px solid #ced4da;
}
.react-datepicker-time__input-container {
  width: 100%;
}
.react-datepicker-time__input {
  width: 100%;
  margin-left: 0 !important;
}
.react-datepicker__day--disabled {
  color: #adb5bd;
  cursor: default;
}
.react-datepicker__day--disabled:hover {
  background-color: white;
}
.date-picker-time-input {
  max-width: 94px;
  border: none;
  text-align: center;
}
.react-datepicker__close-icon::after {
  background: none;
  border-radius: 0;
  padding: 0 8px 0 0;
  font-size: 22px;
  color: #ccc;
}

.lazy-load-image-background {
  width: 100%;
  height: 100%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  transition: all 0.3s !important;
}
